/*!
 / ShapeFromShading - Creating heightmaps out of 2D seal images.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /

@import variables

#detail

  h1
    font-family: $font-accent
    margin: 0 0 .2em

  .tags
    display: flex
    flex-wrap: wrap
    gap: .3em .4em

    .tag
      background: $grey-60
      border-radius: 4px
      padding: .1em .4em .2em .4em

  .main
    img
      width: 10em
      height: 10em
  .big-seal
    img
      width: 20em
      height: auto

  .links
    a
      color: $wax-100
      font-family: $font-content
      font-size: 1.3em
      margin-top: .3em

  .license
    width: 15em
