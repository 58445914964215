@import "icons"
@import "variables"

.action-button
  display: flex
  align-items: center
  gap: .4em
  height: 2.5em
  min-width: 10em
  font-size: 1.6em
  text-decoration: none

  cursor: pointer
  user-select: none

  .icon
    position: relative
    width: 1.6em
    height: 1.6em
    background-position: center
    background-size: cover
    top: 0

    transition: top .1s ease

  .text
    font-family: $font-accent
    color: $grey-100
    font-size: 1em
    white-space: normal

    transition: font-size .5s ease

  &:active .icon
    top: .2em
