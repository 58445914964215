/*!
 / ShapeFromShading - Creating heightmaps out of 2D seal images.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /

.download-icon
  background: url(../assets/icons/download.svg)
.upload-icon
  background: url(../assets/icons/upload.svg)
.upload-alt-icon
  background: url(../assets/icons/upload_alt.svg)
.undo-icon
  background: url(../assets/icons/undo.svg)
.redo-icon
  background: url(../assets/icons/redo.svg)
.forward-icon
  background: url(../assets/icons/forward.svg)
.right-icon
  background: url(../assets/icons/right.svg)
