/*!
 / ShapeFromShading - Creating heightmaps out of 2D seal images.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /

@import variables

.container__row
  h1
    margin-top: 2em
    font-size: 150%
    color: #707070
    @include create-mq($grid__bp-md)
      font-size: 200%

  .info-section
    display: flex
    flex-direction: row
    align-items: center

    *
      flex-basis: 100%

  @media screen and (max-width: 900px)
    .info-section
      flex-direction: column

      &:nth-of-type(2n)
        flex-direction: column-reverse

  .picture
    background-color: black
    padding: 2em
    margin: 3em
    @include create-mq($grid__bp-md)
      padding: 3em
      margin: 6em

      @media screen and (max-width: 900px)
        margin: 0 6em

    img
      width: 100%
      height: auto

  .text
    display: block
    padding: 5em

    @media screen and (max-width: 900px)
      padding: 3em 5em 0

    a
      color: $grey-100

    h2
      transform: rotate(-3deg)
      color: red
      font-size: 170%
      border: 4px solid red
      border: 8px double red
      display: inline-block
      padding: 0.4em
      text-transform: uppercase
      mask-image: url('../assets/grunge_560w.png')
      mask-size: 944px 604px
      mix-blend-mode: multiply
      @include create-mq($grid__bp-md)
        font-size: 300%

    p
      font-size: 120%
      @include create-mq($grid__bp-md)
        font-size: 200%

    ol
      font-size: 120%
      @include create-mq($grid__bp-md)
        font-size: 200%
  img
    &#line
      width: 100%

  #contact
    margin-bottom: 1.2em
    a
      width: 20%
      margin: 0 auto
      font-size: 1.2em

      &.envelope
        display: block
        width:   24rem
        height:  14rem
        position: relative
        background-color: #F5E6DD
        background-image: -webkit-linear-gradient(235.5deg, rgba(  0,   0,   0, 0.0) 0%, rgba(  0,   0,   0, 0.0) 48%, rgba(  0,   0,   0, 0.1) 49%,  rgba(225, 215, 205, 0.7) 50%, rgba(225, 215, 205, 0.0) 52%, rgba(225, 215, 205, 0.0) 100%), -webkit-linear-gradient(-235.5deg,rgba(225, 215, 205, 0.0) 0%,rgba(225, 215, 205, 0.0) 48%,rgba(225, 215, 205, 0.7) 49%,rgba(  0,   0,   0, 0.1) 50%,rgba(  0,   0,   0, 0.0) 52%,rgba(  0,   0,   0, 0.0) 100%),-webkit-linear-gradient(-235.5deg,rgba(  0,   0,   0, 0.0) 0%,rgba(  0,   0,   0, 0.0) 48%,rgba(  0,   0,   0, 0.1) 49%,rgba(225, 215, 205, 0.5) 50%,rgba(225, 215, 205, 0.0) 52%,rgba(225, 215, 205, 0.0) 100%),-webkit-linear-gradient(235.5deg,rgba(225, 215, 205, 0.0) 0%,rgba(225, 215, 205, 0.0) 48%,rgba(225, 215, 205, 0.5) 49%,rgba(  0,   0,   0, 0.1) 50%,rgba(  0,   0,   0, 0.0) 52%, rgba(  0,   0,   0, 0.0) 100%),-webkit-linear-gradient(left,  rgba(51, 41, 31, 0.4) 0%,  rgba(51, 41, 31, 0.3) 5%,  rgba(51, 41, 31, 0.2) 12%,  rgba(51, 41, 31, 0.0) 50%,  rgba(51, 41, 31, 0.2) 88%,  rgba(51, 41, 31, 0.3) 95%,  rgba(51, 41, 31, 0.4) 100%),  -webkit-linear-gradient(top,  rgba(51, 41, 31, 0.20) 0%,  rgba(51, 41, 31, 0.00) 20%,  rgba(51, 41, 31, 0.00) 50%,  rgba(51, 41, 31, 0.20) 88%,  rgba(51, 41, 31, 0.30) 95%,  rgba(51, 41, 31, 0.50) 100%),
        background-position: 105% 215%, -5% 215%, 100% -80%,  0% -80%, center, center, center
        background-size: 12rem 12rem, 12rem 12rem, 12rem 12rem, 12rem 12rem, auto, auto, auto
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat, repeat, repeat
        box-shadow: 0  1px  0   rgba(255, 255, 255, 0.6) inset, 0  2px  0   rgba(255, 255, 255, 0.2) inset, 0 -3px  3px rgba(  0,   0,   0, 0.3) inset, 0  2px  3px rgba(  0,   0,   0, 0.4), 0  6px 12px rgba(  0,   0,   0, 0.2)
        border-radius: 0.5rem
        text-decoration: none

      span
        &.seal
          display: block
          width:   4.4rem
          height:  4.4rem
          position: absolute
          left:     50%
          top:      52%
          margin:   -2.2rem 0 0 -2.2rem
          border-radius: 2.8rem 2.3rem 3.2rem 2.0rem
          background:   red
          color:        red
          border-color: red
          box-shadow: 0 -2px 4px rgba(  0,   0,   0, 0.2) inset, 0  3px 3px rgba(255, 255, 255, 0.6) inset, 0  1px 5px rgba(  0,   0,   0, 0.8)
          text-align: center
          line-height: 3.6rem
          font-size:   0.9rem
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6)

        &.embossed
          display: block
          width:   80%
          height:  80%
          position: absolute
          top:      10%
          left:     10%
          box-shadow: 0  -2px 2px rgba(205, 205, 205, 0.1) inset, 0   3px 3px rgba( 30,  30,  30, 0.1) inset, 0   1px 2px rgba( 30,  30,  30, 0.6) inset
          border-radius: 999rem
          background-image: -webkit-linear-gradient(top, rgba( 51, 51, 51, 0.3) 0%, rgba( 51, 51, 51, 0.2) 100%)
