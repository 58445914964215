/*!
 / ShapeFromShading - Creating heightmaps out of 2D seal images.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /


@import variables

*
  box-sizing: border-box

html, body
  height: 100%
  width: 100%
  margin: 0
  padding: 0

html
  font-size: 16px

body
  margin: 0

  font-size: 62.5%
  font-family: $font-content

  .container
    max-width: $grid__bp-md * 1px
    box-sizing: border-box

    &--fluid
      margin: 0
      max-width: 100%

    &__row
      display: flex
      flex-wrap: wrap
      width: 100%

    @at-root
      [class*='container__col-']
        display: flex
        align-items: center
        justify-content: space-around

    @each $modifier, $breakpoint in $map-grid-props
      @include create-col-classes($modifier, $grid__cols, $breakpoint)

header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 2em
  background: $grey-80
  font-size: 1.5em
  font-family: $font-content

  .logo img
    display: inline
    height: 2em
    width: auto

  menu
    margin: .6em 0 .8em

    a
      margin: 0 1.5em
      color: $background
      text-decoration: none

footer
  text-align: center
  background: $background
  font-size: $size-button
  font-family: $font-content

  margin-top: auto
  min-height: 50px

  display: flex
  justify-content: center
  align-items: center

  border-top: none thick $grey-80

  a
    margin: 0 1.5em
    color: $grey-100
    text-decoration: none

.padding-page
  padding: 1em 2em

.screen-height-page
  display: flex
  flex-direction: column
  height: 80vh /* TODO replace this with calc(100vh - header) */

#app
  height: 100vh
  margin: 0
  display: flex
  flex-direction: column