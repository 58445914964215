/*!
 / ShapeFromShading - Creating heightmaps out of 2D seal images.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /

@import variables
@import actionButton

.container__row
  .container__col-sm-12
    .teaser
      position: relative

      video
        max-width: 100%

      .over-video
        display: flex
        flex-direction: column
        align-items: flex-start
        position: absolute
        top: 50%
        left: 0

        h1
          padding: .1em .2em .1em .1em
          border-radius: 0 2px 2px 0
          margin: 0
          background-color: $wax-100
          color: $background
          font-family: $font-accent
          font-weight: bold
          font-size: 1em
          letter-spacing: -0.02em

          &:first-of-type
            border-bottom-right-radius: 0

        @include create-mq($grid__bp-md)
          font-size: 24px
          line-height: 30px

        @include create-mq($grid__bp-lg)
          font-size: 42px
          line-height: 50px

    .faq
      background-color: $grey-60
      width: 100%
      margin-top: 40px
      display: flex
      padding: 0 3em

      .tab__content a
        color: $wax-100
        text-decoration: underline

      h2
        font-family: $font-accent
        font-weight: bold
        font-size: 34px
        line-height: 41px
        letter-spacing: -0.02em

      .questions
        width: 100%

  .links
    display: flex
    flex-direction: row
    align-items: center    
    margin: 0 auto

    *
      flex-basis: 100%

    @media screen and (max-width: 900px)
      flex-direction: column

    .container__col-sm-4      
      .group, .group_center        
        text-align: center
        position: relative
        margin: 0px
       
        @media screen and (max-width: 900px)
          margin: 20px

        h2
          font-family: $font-accent
          font-weight: bold
          font-size: 34px
          line-height: 41px
          letter-spacing: -0.02em

        img
          margin: 3em
          max-width: 80%

        p
          font-family: $font-content
          font-size: 14px
          line-height: 19px
          letter-spacing: -0.02em
          height: 40px

        .action-button
          background-color: $grey-80
          margin: 0 auto
          width: 8em          
          border-radius: 4px

          .text 
            color: $background
            font-size: 16px
            padding: 0

          .icon
            max-width: 2em

      .group_center
        &:before, 
        &:after
          content: ""
          position: absolute
          bottom: 10%
          height: 80%
          width: 1px

          @media screen and (max-width: 900px)
            width: 80%
            height: 1px

        &:before
          left: 0
          border-left: 1px solid $grey-80
          
          @media screen and (max-width: 900px)
            border-left: none
            border-top: 1px solid $grey-80            
            left: 10%
            top: 0px

        &:after 
          right: 0
          border-right: 1px solid $grey-80

          @media screen and (max-width: 900px)
            border-right: none
            border-bottom: 1px solid $grey-80
            left: 10%
            bottom: -40px
