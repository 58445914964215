/*!
 / FabSeal Website.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /

@import variables

.fileUpload

  form
    width: 100%
    height: 100%
    position: relative

    input
      width: 100%
      height: 100%
      opacity: 0
      position: absolute
      cursor: pointer

    .dropbox
      user-select: none
      pointer-events: none
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      height: 100%
      background: url("../assets/blank.png") no-repeat center
      background-size: contain
      position: absolute
      top: 0
      cursor: pointer

      .text
        text-align: center

        h1
          color: $background
          margin: 0 0 .5em

        p
          font-size: 1.2em
          text-align: center
          padding: 0
          margin: 0
          color: $background
