@import "variables"

.big-seal-page
  display: grid
  grid-template-columns: 1fr 50% 1fr
  grid-template-rows: repeat(3, 75vh)
  flex: 1
  height: 75vh
  justify-content: center

  .toolbar
    display: flex
    height: 100%
    flex-direction: column
    justify-content: space-between
    overflow-y: auto

    .main
      flex: 1
      display: flex
      flex-direction: column
      justify-content: center

    .info
      font-size: 1.5em
      font-family: $font-content
      text-align: center

  .big-seal
    #success
      justify-content: center
    img
      width: 80%
      display: flex
      margin: auto


.alert
  position: fixed
  z-index: 10
  text-align: center
  font-family: $font-accent
  font-size: 3em
  padding: 1em 2em
  top: 50vh
  left: 50vw
  background: $grey-60
  border-radius: 4px
  transform: translate(-50%, -50%)

