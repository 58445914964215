@import variables

.datasets
  display: grid
  grid-template-columns: repeat(auto-fill, 30em)
  justify-content: center
  gap: 6em 4em

  .dataset
    position: relative
    top: 0
    padding: 2em
    width: 100%
    box-shadow: 0 0 15px $grey-60
    border-radius: 2px
    text-decoration: none
    color: $grey-100
    text-align: center
    transition: box-shadow .2s ease, top .2s ease

    &.fake
      display: flex
      flex-direction: row
      justify-content: center
      align-items: flex-end

      border: 2px dashed $grey-60
      box-shadow: none

      font-family: $font-content
      font-size: 1.2em
      color: $grey-80

    &:not(.fake):hover
      box-shadow: 0 0 20px $grey-80
      top: -2px

    img
      height: 20em
      width: auto
      border-radius: 1px

    h1
      font-family: $font-accent
      padding: 0
      margin: 1em 0 0

    p
      font-family: $font-content
