/*!
 / ShapeFromShading - Creating heightmaps out of 2D seal images.
 / Copyright (C) 2021
 / Joana Bergsiek, Leonard Geier, Lisa Ihde, Tobias Markus, Dominik Meier, Paul Methfessel
 / This program is free software: you can redistribute it and/or modify
 / it under the terms of the GNU General Public License as published by
 / the Free Software Foundation, either version 3 of the License, or
 / (at your option) any later version.
 /
 / This program is distributed in the hope that it will be useful,
 / but WITHOUT ANY WARRANTY; without even the implied warranty of
 / MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 / GNU General Public License for more details.
 /
 / You should have received a copy of the GNU General Public License
 / along with this program.  If not, see <https://www.gnu.org/licenses/>.
 /

@import variables

.siegel3d
  width: 100%
  height: 100%

  canvas
    position: absolute
    z-index: -1
    left: 0
    top: 0
    bottom: 0
    right: 0

