@import variables

.dropdown
  display: flex
  align-items: center

  label
    font-size: 1.4em
    font-family: $font-content
    margin-right: 1em

  select
    font-family: $font-content
    appearance: none
    border: 1px solid $grey-100
    border-radius: 2px
    text-align: center
    padding-right: 1.4em
    background: url("../assets/icons/down.svg") no-repeat right, center
    background-size: 1.8em
